import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1279.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
<path d="M8000 7473 l-65 -68 68 65 c37 35 67 66 67 67 0 8 -10 -2 -70 -64z"/>
<path d="M7865 7470 c33 -33 62 -60 64 -60 3 0 -21 27 -54 60 -33 33 -62 60
-64 60 -3 0 21 -27 54 -60z"/>
<path d="M8169 7468 l65 -67 -20 -23 -19 -23 23 19 c22 19 22 19 60 -15 l37
-34 -34 38 -35 37 40 43 39 42 -43 -40 -42 -39 -68 64 -67 65 64 -67z"/>
<path d="M8470 7333 l-55 -58 58 55 c53 50 62 60 54 60 -1 0 -27 -26 -57 -57z"/>
<path d="M8610 7337 c0 -4 16 -23 35 -43 19 -19 35 -38 35 -41 0 -4 -17 -25
-37 -48 l-38 -40 40 38 c23 20 44 37 48 37 4 0 40 -32 79 -71 l70 -71 -33 -36
-34 -37 37 34 37 34 71 -73 71 -73 -43 -46 -43 -46 46 44 47 43 41 -41 c23
-23 41 -37 41 -33 0 4 -16 23 -35 42 -20 19 -33 40 -31 47 3 6 0 10 -7 7 -7
-2 -43 27 -81 65 -63 63 -68 72 -55 88 13 17 13 17 -4 4 -16 -13 -25 -8 -92
59 -41 40 -71 78 -68 84 3 6 2 8 -2 3 -5 -4 -28 12 -52 35 -24 23 -43 39 -43
35z"/>
<path d="M7902 7125 l28 -29 -42 -46 -43 -45 45 42 45 43 55 -53 55 -52 -52
55 -53 56 20 22 20 22 -22 -20 -22 -20 -31 27 -30 28 27 -30z"/>
<path d="M8160 7023 l-55 -58 58 55 c31 30 57 56 57 57 0 8 -10 -1 -60 -54z"/>
<path d="M8330 7008 l54 -58 -34 -37 c-20 -22 -12 -16 18 12 49 46 71 73 36
45 -13 -11 -23 -5 -72 41 l-57 54 55 -57z"/>
<path d="M8383 6863 c-21 -8 -14 -25 7 -18 16 5 20 2 20 -14 0 -25 18 -28 27
-5 4 11 -1 22 -12 30 -20 15 -23 15 -42 7z"/>
<path d="M8440 6805 c-8 -9 -6 -14 4 -18 8 -3 17 -1 20 6 2 7 5 3 5 -8 2 -45
54 -62 67 -23 l7 23 8 -23 c9 -22 39 -31 39 -11 0 8 5 8 15 -1 12 -10 18 -10
30 0 18 15 19 36 3 46 -6 4 -8 3 -5 -2 3 -6 2 -16 -4 -24 -6 -11 -9 -6 -9 18
0 39 -21 40 -31 1 l-6 -24 -4 25 c-3 20 -10 25 -34 25 -22 -1 -31 -6 -33 -20
-6 -33 -19 -38 -14 -7 3 26 1 30 -22 30 -14 0 -30 -6 -36 -13z"/>
<path d="M9075 6718 l-50 -53 53 50 c48 46 57 55 49 55 -1 0 -25 -24 -52 -52z"/>
<path d="M7835 6698 l-50 -53 53 50 c48 46 57 55 49 55 -1 0 -25 -24 -52 -52z"/>
<path d="M8037 6690 l43 -46 -33 -34 -32 -35 35 33 36 32 74 -75 c67 -67 72
-76 59 -92 -13 -17 -13 -17 4 -4 17 13 25 8 90 -57 l71 -72 -34 -37 -35 -38
38 34 37 35 43 -40 42 -39 -39 42 -40 42 20 23 19 23 -23 -19 -23 -20 -72 72
-71 72 34 37 35 38 -38 -35 -37 -34 -75 74 -75 74 22 26 22 25 -24 -22 -24
-23 -46 43 -45 42 42 -45z"/>
<path d="M8670 6623 c-30 -32 -37 -41 -15 -21 l40 38 55 -53 55 -52 -53 55
c-49 53 -51 57 -35 73 10 9 15 17 12 17 -2 0 -29 -26 -59 -57z"/>
<path d="M9104 6533 l40 -42 -20 -23 -19 -23 23 19 23 20 67 -65 67 -64 -65
67 -64 68 34 37 35 38 -38 -34 -37 -35 -43 40 -42 39 39 -42z"/>
<path d="M8770 6413 l-55 -58 58 55 c31 30 57 56 57 57 0 8 -10 -1 -60 -54z"/>
<path d="M9210 6255 l-65 -65 70 -68 70 -67 -65 68 -64 67 64 65 c35 36 62 65
59 65 -2 0 -34 -29 -69 -65z"/>
<path d="M8782 6245 l58 -60 -48 -50 -47 -50 50 48 51 47 24 -23 24 -22 -22
25 c-22 26 -22 26 -2 48 l20 22 -22 -20 -22 -20 -61 58 -60 57 57 -60z"/>
<path d="M8440 6085 c-24 -25 -42 -45 -39 -45 3 0 25 20 49 45 24 25 42 45 39
45 -3 0 -25 -20 -49 -45z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
